import React from "react";
import { Icon } from "@ryerson/frontend.assets";
import { Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import styled from "@emotion/styled";
import { Link } from "@ryerson/frontend.navigation";
import { css } from "@emotion/react";
import EvenOffImageWithText, {
	EvenOddImageWithTextCard,
} from "@components/Shared/EvenOddImageWithText/EvenOffImageWithText";

export type SubPagesCard = {
	title: string;
	actionUrl: string;
	description: string;
	imageUrl: string;
};

export type SubPagesContent = {
	cards: SubPagesCard[];
};

export type SubPagesProps = {
	content: SubPagesContent;
};

const Contain = styled.div`
	width: 100%;
	height: 650px;
	display: block;
`;

const Left = styled.div`
	position: absolute;
	right: 49%;
	width: 51%;
	max-width: 740px;
	padding: 75px 0px 75px 95px;
	height: 510px;
	background-size: cover;
	background-position: center center;
`;

const Right = styled.div`
	position: absolute;
	left: 51%;
	width: 49%;
	max-width: 700px;
	height: 650px;
	background-size: cover;
	background-position: center center;
	@media (min-width: 1428px) {
		max-width: 710px;
	}
`;

const Overlay = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	padding: 75px 0 75px 95px;
	height: 100%;
	background: linear-gradient(6.93deg, rgba(0, 0, 0, 0.6) 10.83%, rgba(0, 0, 0, 0.132) 79.88%);
`;

const SubPages: React.FunctionComponent<SubPagesProps> = ({ content }) => {
	const { theme } = useTheme();

	const cardArray: EvenOddImageWithTextCard[] = [];
	content.cards.forEach((card) => {
		cardArray.push({
			title: card.title,
			description: card.description,
			imageUrl: card.imageUrl,
			actionUrl: card.actionUrl,
		});
	});

	const [paddingLeft, setPaddingLeft] = React.useState<number>(140);
	const [paddingRight, setPaddingRight] = React.useState<number>(0);

	React.useEffect(() => {
		if (window) {
			window.addEventListener("resize", () => {
				let width = window.innerWidth;
				if (width >= 1440) {
					setPaddingLeft(140);
					setPaddingRight(0);
				} else if (width <= 1200) {
					setPaddingLeft(20);
					setPaddingRight(140);
				} else if (width > 1200 && width < 1440) {
					setPaddingLeft((width - 1160) / 2);
					setPaddingRight(0);
				}
			});
			let width = window.innerWidth;
			if (width >= 1440) {
				setPaddingLeft(140);
				setPaddingRight(0);
			} else if (width <= 1200) {
				setPaddingLeft(20);
				setPaddingRight(140);
			} else if (width > 1200 && width < 1440) {
				setPaddingLeft((width - 1160) / 2);
				setPaddingRight(0);
			}
		}
	}, []);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="secondary" vPadding="0px">
					<Contain>
						<Left
							css={css`
								background-image: url(${content.cards[0].imageUrl});
							`}
						>
							<Overlay
								css={css`
									padding-left: ${paddingLeft + "px"} !important;
									padding-right: ${paddingRight + "px"} !important;
								`}
							>
								<Flex
									css={css`
										height: 100%;
									`}
									direction="column"
									justifyContent="flex-end"
								>
									<FlexItem>
										<Typography
											type="tertiary"
											css={css`
												white-space: pre-line;
												margin-bottom: 53px;
											`}
											variant="h1"
										>
											{content.cards[0].title}
											<Link to={content.cards[0].actionUrl} gatsby={true}>
												<Icon
													icon="chevron-right"
													circled={true}
													css={css`
														margin-left: 25px;
														display: inline-flex;
														line-height: 56px;
														vertical-align: middle;
														cursor: pointer;
													`}
													color={theme.colors.primary.white}
													size="xl"
												/>
											</Link>
										</Typography>
										<Typography
											css={css`
												display: block;
												max-width: 360px;
												margin-top: 30px;
											`}
											size="md"
											color={theme.colors.primary.white}
										>
											{content.cards[0].description}
										</Typography>
									</FlexItem>
								</Flex>
							</Overlay>
						</Left>
						<Right
							css={css`
								background-image: url(${content.cards[1].imageUrl});
							`}
						>
							<Overlay>
								<Flex
									css={css`
										height: 100%;
									`}
									direction="column"
									justifyContent="flex-end"
								>
									<FlexItem>
										<Typography
											type="tertiary"
											css={css`
												white-space: pre-line;
												margin-bottom: 53px;
											`}
											variant="h1"
										>
											{content.cards[1].title}
											<Link to={content.cards[1].actionUrl} gatsby={true}>
												<Icon
													icon="chevron-right"
													circled={true}
													css={css`
														margin-left: 25px;
														display: inline-flex;
														line-height: 56px;
														cursor: pointer;
														vertical-align: middle;
													`}
													color={theme.colors.primary.white}
													size="xl"
												/>
											</Link>
										</Typography>
										<Typography
											css={css`
												display: block;
												max-width: 360px;
												margin-top: 30px;
											`}
											size="md"
											color={theme.colors.primary.white}
										>
											{content.cards[1].description}
										</Typography>
									</FlexItem>
								</Flex>
							</Overlay>
						</Right>
					</Contain>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="primary" vPadding="20px">
					<EvenOffImageWithText cards={cardArray} />
				</ContentSection>
			</Media>
		</>
	);
};

export default SubPages;
